// src/Solitaire.js
import React from 'react';

const SolitaireGame2 = () => {
  return (
    <div>
      <iframe
        src="https://www.freecell.io/uploads/games/files/freecellio/solitaire-windows-xp/1.3.0/?v=8"
        title="Spider Solitaire Windows XP"
        width="507"
        height="380"
        style={{ border: 'none' }}
      ></iframe>
    </div>
  );
};

export default SolitaireGame2;
